import { formatNums } from './helpers'

$(document).ready(function() {
    $('.range-slider').each(function () {
        let option = $(this).data('json') || {}
        $(option.target).val(formatNums(option.from, '\'', 0, ''))

        option.onChange = (data) => {
            $(option.target).val(formatNums(data.from, '\'', 0, ''))
        }

        $(this).ionRangeSlider($(this).data('json'));

        const instance =  $(this).data('ionRangeSlider')

        $(option.target).on('blur', function (e) {
            let val = e.target.value;
            val = val.replace(/\D/g, '')
            val = parseInt(val)

            let exceedVal = val % option.step
            if (exceedVal > 0) {
                val = (val - exceedVal) + option.step
            }

            if (val < option.min) {
                val = option.min
            } else if (val > option.max) {
                val = option.max
            }

            $(this).prop("value", formatNums(val, '\'', 0, ''))
            instance.update({from: val})
        })
    })
})

